import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { graphql } from 'gatsby';
import first from 'lodash/first';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageBody from '../components/PageBody';
import PriceFeeds from '../components/PriceFeeds';
import MainCol from '../components/PageBody/MainCol';
import SideRailsMenu from '../components/SideRailsMenu';
import JumbotronBlock from '../components/JumbotronBlock';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';

const propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
};

const defaultProps = {
  data: null,
  errors: [],
};

function Product({ data, errors }) {
  const pageData = first(data.prismic.allProducts.edges);
  const sidebarData = { products: data.prismic.allProducts.edges };
  const universalValues = first(data.prismic.allSmall_universal_valuess.edges)
    .node.universal_values;

  if (!pageData) {
    return null;
  }

  const {
    dxfeed_symbol,
    futures_symbol,
    index_symbol,
    embed_video,
    header_banner,
    seo_title,
    seo_meta_description,
    title,
    _meta,
    body,
  } = pageData.node;
  const productTitle = title && first(title).text;

  return (
    <Layout>
      <SEO
        title={seo_title || pageName}
        description={seo_meta_description}
        lang={_meta}
      />
      <JumbotronBlock
        symbol={futures_symbol}
        title={productTitle}
        url={header_banner && header_banner.url}
        embedVideo={embed_video}
      />
      <Row>
        {/* TODO: Hard coding /products URL here is not ideal, we should get the url dynamically from Prismic instead. Hard-coding for now for demo. */}
        <SideRailsMenu
          title='Products'
          titleUrl={'/products'}
          data={sidebarData}
          pageMeta={_meta}
        />
        <MainCol lg={7} md={12}>
          <ColumnWrapper>
            {body && (
              <PageBody
                body={body}
                productSymbol={dxfeed_symbol}
                universalValues={universalValues}
              />
            )}
          </ColumnWrapper>
        </MainCol>
        <PriceFeeds />
      </Row>
    </Layout>
  );
}

export const query = graphql`
  query ProductPageQuery($uid: String) {
    prismic {
      allProducts(uid: $uid) {
        edges {
          node {
            dxfeed_symbol
            futures_symbol
            index_symbol
            header_banner
            embed_video
            play_video
            seo_meta_description
            seo_title
            sort_order
            subtitle
            title
            _meta {
              id
              uid
              tags
              type
              lang
            }
            body {
              ... on PRISMIC_ProductBodyText_block {
                type
                primary {
                  text_block_header
                  tooltip
                  disclaimer_text
                }
                fields {
                  text_block_content
                }
              }
              ... on PRISMIC_ProductBodyPrice_movement {
                label
                type
                primary {
                  block_title
                  tooltip
                  contract_link_text
                  contract_link {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                    ... on PRISMIC_Standard_page {
                      _linkType
                      _meta {
                        uid
                        lang
                        type
                      }
                    }
                    ... on PRISMIC_Contracts_page {
                      _linkType
                      _meta {
                        uid
                        lang
                        type
                      }
                    }
                  }
                }
                fields {
                  data_type
                  dxfeed_value
                  dxfeed_previous_value
                  label
                  override_1
                  override_2
                }
              }
              ... on PRISMIC_ProductBodyMarket_comparisons {
                label
                type
                primary {
                  header
                  subheader
                  tooltip
                }
                fields {
                  col1
                  col2
                  col3
                  col4
                }
              }
              ... on PRISMIC_ProductBodyContract_specs {
                label
                type
                primary {
                  header
                  tooltip
                }
                fields {
                  label
                  label_link
                  value
                  dxfeed_value
                  dxfeed_previous_value
                  ipf_field_name
                }
              }
              ... on PRISMIC_ProductBodyPrice_history_line_graph {
                type
                label
                primary {
                  tooltip
                }
              }
              ... on PRISMIC_ProductBodyMargin {
                type
                label
                primary {
                  header
                  tooltip
                }
                fields {
                  label
                  ipf_field_name
                  override_value
                }
              }
              ... on PRISMIC_ProductBodyTwo_columns_image {
                type
                label
                fields {
                  additional_text
                  image
                  text_block
                  title
                  button_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  button_link_text
                }
              }
            }
          }
        }
      }
      allSmall_universal_valuess {
        edges {
          node {
            universal_values {
              label
              label_link
              reference_id
              value
            }
          }
        }
      }
    }
  }
`;

Product.propTypes = propTypes;
Product.defaultProps = defaultProps;

export default Product;
